import React from "react";
import { Link } from "gatsby";

const BlockContentBlogSideBlock = ({ data, context, ...props }) => {
  return (
    <div
      className="bg-no-repeat bg-[center_top_10px] px-5 pt-32 pb-7 rounded-[13px] text-[#3D4A5C] space-y-6  "
      style={{
        backgroundColor: `${data.color.color}`,
        backgroundImage: `url('${data.relationships.image.localFile.publicURL}')`,
      }}
    >
      <Block.Title>{data.title}</Block.Title>
      <Block.Body content={data.body.processed} />
      <Block.Cta {...data.cta} />
    </div>
  );
};

const BlockContentBlogPostSideWithoutImage = ({ data, context, ...props }) => {
  return (
    <div
      className=" px-5 pt-8 pb-7 rounded-[13px] text-[#3D4A5C] space-y-[20px] "
      style={{
        backgroundColor: `${data.color.color}`,
      }}
    >
      <Block.Title>{data.title}</Block.Title>
      <Block.Body content={data.body.processed} />
      <Block.Cta {...data.cta} />
    </div>
  );
};

const Block = () => {
  return <div>block</div>;
};

Block.Title = ({ children, className }) => {
  return <h3 className={"leading-[118%] text-[28px] " + className}>{children}</h3>;
};

Block.Body = ({ content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className="text-lg leading-[25px] "
    />
  );
};

Block.Cta = ({ title, uri }) => {
  return (
    <div>
      <Link
        to={uri.replace("internal:/", "/")}
        className="border rounded-[15px] bg-[#455468] text-xs text-white py-1 px-3 side_banner_cta"
      >
        {title}
      </Link>
    </div>
  );
};

export { BlockContentBlogSideBlock, BlockContentBlogPostSideWithoutImage };
