import React from "react"
import { Link } from "gatsby"

const CtaCard = ({ title, description, image, link, color, layout }) => {
    if (layout === "left-2-right") {
      return (
        <div
          className=" p-7 text-[#3D4A5C] rounded-[13px] flex flex-col lg:flex-row space-x-4 break-before-page	print:pt-32"
          style={{ backgroundColor: `${color}` }}
        >
          <CtaCard.Image image={image} />
          <div className="space-y-4">
            <CtaCard.Title title={title} />
            <div className="flex flex-col lg:flex-row justify-between items-center">
              <CtaCard.Body description={description} />
              <CtaCard.Link {...link} />
            </div>
          </div>
        </div>
      );
    } else if (layout === "top-2-bottom") {
      return (
        <div
          className=" p-7 text-[#3D4A5C] rounded-[13px] flex flex-row space-x-4 justify-between items-center break-before-page print:pt-32	"
          style={{ backgroundColor: `${color}` }}
        >
          <div className="space-y-4 lg:w-2/3">
            <CtaCard.Title title={title} />
            <CtaCard.Body description={description} />
            <CtaCard.Link {...link} />
          </div>
  <div className="lg:w-1/3">
          <CtaCard.Image image={image} />
          </div>
        </div>
      );
    } else {
      return <div>layout not recognized</div>;
    }
  };
  
  CtaCard.Title = ({ title }) => {
    return (
      <h4 className="font-readexpro text-2xl leading-[110%] tracking-[-0.72px] font-normal">
        {title}
      </h4>
    );
  };
  
  CtaCard.Body = ({ description }) => {
    return <div className="text-sm leading-[22px]" dangerouslySetInnerHTML={{__html:description }} />;
  };
  
  CtaCard.Image = ({ image }) => {
    if(!image) return false
    return (
      <div>
        <img src={image.src} alt={image.alt} />
      </div>
    );
  };
  
  CtaCard.Link = ({ title, uri }) => {
    if( ! uri ) return false;
    return (
      <div className="pt-4 lg:pt-0">
        <Link
          to={uri.replace("internal:/", "/")}
          className="inline-block py-1 px-3 border rounded-[13px] text-white bg-[#455468] whitespace-nowrap"
        >
          {title}
        </Link>
      </div>
    );
  };


export default CtaCard
