import { graphql, useStaticQuery } from "gatsby"


const useFiles = () => {

  const data = useStaticQuery(graphql`
    query {
      files: allFileFile {
        totalCount
        nodes {
          localFile {
            url
            publicURL
          }
        }
      }
    }
  `)

  const res = data.files.nodes.map((e) => ({ ...e.localFile }))
  return res
}

export default useFiles
