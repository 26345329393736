import React, { useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import Image from "../components/Image";
import { relativeToAbsulote } from "../util/common";
import useFiles from "../hooks/useFiles";
import HubspotForm from "react-hubspot-form";
import CtaCard from "../components/cards/cta";
import arrowDownIcon from "../images/menu.png";
import BreadCrumbs from "../components/resources/breadcrumbs";
import SocialToolbar from "../components/social-toolbar";
import {
  BlockContentBlogSideBlock,
  BlockContentBlogPostSideWithoutImage,
} from "../components/blocks/content";

function BlogPost({ data: { node, posts, site }, location }) {
  const {
    id,
    title,
    body,
    summary,
    script,
    auther,
    date,
    image,
    relationships,
    path,
    noNumbers,
    authorPhoto,
    authorPosision,
  } = node;

  const Og = node?.relationships?.OGImage?.localFile?.publicURL
    ? site.siteMetadata.siteUrl +
      node?.relationships?.OGImage?.localFile?.publicURL
    : "";

  const seo = getMetatags(node.metatag, Og);
  const { prev, next } = findPrevNext(id, posts.nodes);

  const files = useFiles();
  const sections = _buildSections(relationships.sections);

  return (
    <Layout showCta={false}>
      <Seo {...seo} />

      <div className="h-20 lg:h-28"></div>
      <div className="container px-4 lg:px-6 ">
        <div className="flex flex-col lg:flex-row  mx-auto w-full justify-between gap-x-8">
          <div className="lg:w-full max-w-[270px] h-8 lg:h-auto">
            <div className="hidden lg:block sticky top-[80px]">
              <BlogPostTOC
                sections={sections}
                noNumbers={noNumbers}
                className="py-[30px] text-[16px] leading-[29px] mb-[1.3rem]"
              />
            </div>
          </div>
          <div className="w-full max-w-[710px]">
            <BreadCrumbs
              tip={path.alias}
              base={site.siteMetadata.siteUrl}
              title={title}
            />

            <div>
              <h1 className="text-[32px] lg:text-[46px] leading-[120%] text-[#3D4A5C] font-readexpro font-light	">
                {title}
              </h1>
            </div>

            <div className=" mt-4">
              <div className="space-x-2  lg:space-x-4 text-[#3D4A5C] flex flex-row items-center">
                {relationships.authorPhoto && (
                  <div className="ml-2">
                    <Image
                      image={relationships.authorPhoto}
                      alt={authorPhoto?.alt}
                      className="rounded-full w-[50px] h-[50px]"
                    />
                  </div>
                )}
                <div className="text-[15px] font-medium">
                  <span className="text-[16px] font-medium text-[#3D4A5C]">
                    {auther}
                  </span>
                  <br />
                  {authorPosision && (
                    <span className="text-[15px] font-[400] text-[#5E718D]">
                      {authorPosision}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:justify-between mt-4 space-y-2 lg:space-y-0 border-t border-b py-4">
              <div className=" ">
                <div className="text-[#AFBACA]"> {date}</div>
              </div>
              <SocialToolbar className="flex flex-row items-center gap-x-[22px] text-[#AFBACA]"  />
            </div>

            <div className="h-1  w-full my-3"></div>

            <div className="lg:hidden">
              <BlogPostTOC
                sections={sections}
                noNumbers={noNumbers}
                open={false}
                className="pt-4 text-[16px] leading-[29px] mb-[1.3rem]  print:hidden"
              />
            </div>
            {/* <div className="lg:hidden h-1 w-full my-3"></div> */}
            <div>
              <BlogPostBody html={summary?.processed} replacements={files} />
            </div>

            <div className="py-2">
              <Image
                image={relationships.image}
                alt={image?.alt}
                className="rounded-t-3xl"
              />
            </div>
            <div className="pt-6">
              <BlogPostMaster
                sections={sections}
                replacements={files}
                noNumbers={noNumbers}
              />
              <BlogPostBody html={body?.processed} replacements={files} />

              <div dangerouslySetInnerHTML={{ __html: script }} />
            </div>

            {relationships.hubspot && <Hubspot {...relationships.hubspot} className=" print:hidden" />}
            <Pagination next={next} previous={prev}  />
          </div>
          <div className="lg:w-full max-w-[270px] space-y-8  print:hidden ">
            <Blocks
              items={relationships.blocks.filter(
                (block) => true || block.type.id === "blog_side_block"
              )}
              className="py-[30px] space-y-8 sticky  top-[108px] "
            />
            {/* <Forms
              items={relationships.blocks.filter(
                (block) => block.type.id === "hubspot_form_for_glossary_"
              )}
              className="space-y-8"
            /> */}
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Blocks = ({ items, className }) => {
  //const blocks = items.map((item, index) => <Block {...item} key={index} />);
  const blocks = items.map((item, index) => <Component data={item} key={index} />);
  return <section className={className}>{blocks}</section>;
};

const Block = () => {
  return <div>block</div>
}

Block.Title = ({ children, className }) => {
  return (
    <h3 className={"leading-[118%]  " + className}>{children}</h3>
  );
};

Block.Body = ({ content }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className="text-lg leading-[25px] "
    />
  );
};

Block.Cta = ({ title, uri }) => {
  return (
    <div>
      <Link
        to={uri.replace("internal:/", "/")}
        className="border rounded-[15px] bg-[#455468] text-xs text-white py-1 px-3"
      >
        {title}
      </Link>
    </div>
  );
};

// const Forms = ({ items, className }) => {
//   const forms = items.map((item, index) => {
//     const data = item.relationships.hubspot;
//     return <Form key={index} {...data} />;
//   });

//   return <section className={className}>{forms}</section>;
// };

const Form = ({ ...item }) => {
  return (
    <div
      className="rounded-[13px] bg-white p-5 space-y-5"
      style={{
        boxShadow:
          "0px 0.5px 2px 0px rgba(96, 97, 112, 0.16), 0px 0px 1px 0px rgba(40, 41, 61, 0.08)",
      }}
    >
      <div className="space-y-4">
        <Block.Title className={"text-[28px]"}>{item.title}</Block.Title>
        <Block.Body content={"<p>" + item.subtitle + "</p>"} />
      </div>
      <div>
        <Form.Body {...item} />
      </div>
    </div>
  );
};

Form.Body = ({ portal_id, form_id, className }) => {
  //console.log(portal_id, form_id);

  return (
    <HubspotForm
      portalId={portal_id}
      formId={form_id}
      //cssRequired=""
      css=" " // !important
      cssClass="sigthfull-hubspot sigthfull-hubspot-blog-side"
      submitButtonClass="sigthfull-hubspot-submit-blog-side "
      errorClass="sigthfull-hubspot-error"
      errorMessageClass="sigthfull-hubspot-error-message"
      //onSubmit={(e) => console.log('Submit!')}
      //onFromSubmit={(e) => console.log('on form submit')}
      //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
      //onReady={ready}
      //loading={<div>Loading...</div>}
      //redirectUrl={redirect}
    />
  );
};

const findPrevNext = (id, items) => {
  const filtered = items.filter((item) => item.path.alias);
  return {
    prev: findPrev(id, filtered),
    next: findNext(id, filtered),
  };
};

const findNext = (id, items) => {
  const items2 = [...items, items[0]];
  const index = items2.findIndex((e) => e.id === id);
  return items2[index + 1];
};

const findPrev = (id, items) => {
  return findNext(id, [...items].reverse());
};

const Pagination = ({ next, previous  }) => {
  const nextPath = next.path.alias + "/";
  const prevPath = previous.path.alias + "/";

  return (
    <section className="lg:pt-20 lg:pb-12  print:hidden">
      <div className="-container -max-w-[1200px] flex flex-row  -justify-between space-x-8 ">
        <div className="w-1/2">
          <div className="flex flex-row items-center  space-x-2">
            <div
              className=" rotate-180"
              width="9px"
              dangerouslySetInnerHTML={{ __html: arrow }}
            />
            <div className="text-black text-base font-semibold 	">
              <Link to={prevPath}>Previous</Link>
            </div>
          </div>
          <div className="text-gray-700 pl-[17px]">{previous.title}</div>
        </div>

        <div className="w-fit text-right w-1/2">
          <div className="flex flex-row items-center justify-end  space-x-2 text-right  w-full ">
            <div className="text-black text-base font-semibold text-right	">
              <Link to={nextPath}>Next</Link>
            </div>
            <div
              className=" text-right "
              width="9px"
              dangerouslySetInnerHTML={{ __html: arrow }}
            />
          </div>
          <div className="text-gray-700 pr-[17px] text-right ">
            {next.title}
          </div>
        </div>
      </div>
    </section>
  );
};

// const BreadCrumbs = ({ tip, base, title }) => {
//   const hpUrl = base + "/";

//   const jsonLD = {
//     "@context": "https://schema.org",
//     "@type": "BreadcrumbList",
//     itemListElement: [],
//   };

//   jsonLD.itemListElement.push({
//     "@type": "ListItem",
//     position: 1,
//     name: "Home",
//     item: base + "/",
//   });

//   jsonLD.itemListElement.push({
//     "@type": "ListItem",
//     position: 2,
//     name: "Resources",
//     item: base + "/resources/",
//   });

//   jsonLD.itemListElement.push({
//     "@type": "ListItem",
//     position: 3,
//     name: title,
//   });

//   return (
//     <>
//       <section className="pb-6 text-[#2D3643] ">
//         <div className="flex flex-row flex-wrap text-sm space-x-4">
//           <Link to={hpUrl}>Home</Link>
//           <span>{" / "}</span>
//           <Link to={"/resources/"}>Resources</Link>
//           <span>{" / "}</span>
//         </div>
//       </section>
//       <StructuredData>{[{ ...jsonLD }]}</StructuredData>
//     </>
//   );
// };

const BlogPostBody = ({ html, replacements }) => {
  const body0 = relativeToAbsulote(html);
  let body = body0 ? body0 : "";
  replacements.forEach((e) => {
    body = body.replace(e.url, e.publicURL);
  });

  return (
    <div className="blog-post-v2" dangerouslySetInnerHTML={{ __html: body }} />
  );
};

const _buildSections = (sections) => {
  let main = 0;
  let secondary = 0;

  const items = sections.map((section, index) => {
    //console.log(main)
    if (section.tag === "H2") {
      main += 1;
      secondary = 0;
    }
    if (section.tag === "H3") {
      secondary += 1;
    }

    const prefix = secondary === 0 ? `${main}. ` : `${main}.${secondary} `;
    return {
      ...section,
      prefix: prefix,
      id: "section-" + (index + 1),
    };
  });

  return items;
};

const BlogPostMaster = ({ sections, replacements, noNumbers }) => {
  return (
    <div>
      {sections.map((section, index) => (
        <div className="scroll-mt-[100px] " key={index} id={section.id}>
          {section.tag === "H2" && (
            <h2 className="text-[24px] font-semibold  pb-2 pt-4 text-[#3D4A5C] leading-[40px]">
              {!noNumbers && section.prefix} {section.title}
            </h2>
          )}
          {section.tag === "H3" && (
            <h3 className="font-bold text-[20px] pb-[0.1rem] pt-[0.8rem] text-[#3D4A5C] leading-[36px]">
              {!noNumbers && section.prefix} {section.title}
            </h3>
          )}

          <BlogPostBody
            html={section?.body?.processed}
            replacements={replacements}
          />

          {section?.ctaTitle && (
            <CtaCard
              title={section.ctaTitle}
              description={section.ctaDescription}
              link={section.ctaLink}
              image={{
                src: section?.relationships?.image?.localFile?.publicURL,
                ...section.image,
              }}
              color={section?.ctaColor?.color}
              layout={section?.ctaLayout}
            />
          )}
        </div>
      ))}
    </div>
  );
};

const BlogPostTOC = ({ className, sections, noNumbers, open = true }) => {
  //console.log(sections)

  const [activeSection, setActiveSection] = useState(-1);
  const [isOpen, setIsOpen] = useState(open);

  const toogle = () => {
    setIsOpen(!isOpen);
  };

  const tree = [];
  let current = -1;
  sections?.map((section) => {
    if (section.tag === "H2") {
      tree.push({ ...section, children: [] });
      current++;
    }
    if (section.tag === "H3") {
      const parent = tree[current];
      parent.children.push(section);
    }
    return null;
  });

  //console.log(tree);

  return (
    <div className={className}>
      <div className="flex flex-row justify-start items-center gap-x-2  mb-4">
        <div className="font-bold text-[18px] ">Table of Contents</div>
        {!open && (
          <button onClick={toogle} className="p-2 ">
            <img src={arrowDownIcon} alt="" />
          </button>
        )}
      </div>

      <ul className={"space-y-5 leading-[20px] " + (isOpen ? "" : "hidden")}>
        {tree.map((section, index) => (
          <li
            className="text-[#3D4A5C] font-medium text-base pl-4 -indent-4"
            key={index}
          >
            <a
              className=" "
              href={"#" + section.id}
              onClick={(e) => {
                navigate("#" + section.id);
                if (activeSection === index) {
                  setActiveSection(-1);
                } else {
                  setActiveSection(index);
                }
              }}
            >
              {!noNumbers && section.prefix} {section.title_thc}
            </a>
            {activeSection === index && (
              <ul className="space-y-2 leading-[20px] mt-4 text-[15px] text-[#5E718D] font-normal">
                {section.children.map((child, index2) => (
                  <li className="pl-3  -indent-3" key={index2}>
                    <a
                      href={"#" + child.id}
                      onClick={(e) => {
                        navigate("#" + child.id);
                      }}
                    >
                      {!noNumbers && child.prefix} {child.title_thc}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

const arrow = `<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.58896 7.99982L0.812715 3.22357C0.295215 2.70607 0.295215 1.86607 0.812715 1.34857C1.33021 0.831074 2.17022 0.831074 2.68772 1.34857L8.45521 7.11607C8.94396 7.60483 8.94396 8.39607 8.45521 8.88357L2.68772 14.6511C2.17022 15.1686 1.33021 15.1686 0.812715 14.6511C0.295215 14.1336 0.295215 13.2936 0.812715 12.7761L5.58896 7.99982Z" fill="#7BA6FF"/>
</svg>
`;

const Hubspot = ({ portal_id, form_id, title, sub_title, className }) => {
  const ready = (form) => {
    // var containers = document.getElementsByClassName("legal-consent-container");
    // for (var x = 0; x < containers.length; x++) {
    //   var container = containers[x];
    //   var fieldset1 = container.closest("fieldset");
    //   var f = container.closest("form");
    //   f.append(fieldset1);
    // }
  };

  return (
    <div className={className} id="the-form">
      <div className="container p-0 lg:p-0 bg-[#F9FAFB]">
        <div className=" text-[#3D4A5C] border  rounded-[12px] py-10 px-7    space-y-3">
          <h2 className=" font-readexpro text-[28px] lg:subtitle-m-bold   text-center">
            {title}
          </h2>
          <p className=" text-[17px] leading-[29px] text-center">{sub_title}</p>
          <HubspotForm
            portalId={portal_id}
            formId={form_id}
            //cssRequired=""
            css=" " // !important
            cssClass="sigthfull-hubspot sigthfull-hubspot-fix"
            submitButtonClass="sigthfull-hubspot-submit-btn sigthfull-hubspot-submit-btn-fix "
            errorClass="sigthfull-hubspot-error"
            errorMessageClass="sigthfull-hubspot-error-message"
            //onSubmit={(e) => console.log('Submit!')}
            //onFromSubmit={(e) => console.log('on form submit')}
            //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
            onReady={ready}
            //loading={<div>Loading...</div>}
            //redirectUrl={redirect}
          />
        </div>
      </div>
    </div>
  );
};

const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    
    case "block_content__blog_side_block":
      comp = (
        <BlockContentBlogSideBlock data={data} context={context} {...props} />
      );
      break;

    case "block_content__blog_post_side_without_image":
      comp = (
        <BlockContentBlogPostSideWithoutImage
          data={data}
          context={context}
          {...props}
        />
      );
      break;


    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};


export default BlogPost;

export const query = graphql`
  query blogPost($id: String!, $status: [Boolean!]!) {
    node: nodeBlog(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      teaser: field_description2
      script: field_description3
      body {
        processed
      }
      summary: field_body_summary {
        processed
      }
      image: field_hero_image {
        alt
      }
      auther: field_title
      authorPosision: field_id
      authorPhoto: field_author_image {
        alt
      }
      date: field_title2
      noNumbers: field_not_show_numbers
      relationships {
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        image: field_hero_image {
          ...Image
        }
        authorPhoto: field_author_image {
          ...Image
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
        category: field_category {
          name
        }
        sections: field_paragraph_repeter {
          tag: field_h_tag
          title_thc: field_title
          title: field_sub_title
          body: field_content {
            processed
          }

          ctaColor: field_color {
            color
          }

          ctaDescription: field_description

          image: field_image {
            alt
          }
          ctaTitle: field_label
          ctaLayout: field_layout
          ctaLink: field_link {
            title
            uri
          }

          relationships {
            image: field_image {
              localFile {
                publicURL
              }
            }
          }
        }
        blocks: field_side_blocks {
          ... on block_content__blog_side_block {
            ...BlogSideBlockContent
          }
          ... on block_content__blog_post_side_without_image {
            ...BlogSideBlockNoImageContent
          }
        }
      }
    }
    posts: allNodeBlog(filter: { status: { in: $status } }) {
      nodes {
        id
        title
        path {
          alias
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
